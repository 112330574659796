import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { BsCloudUpload } from "react-icons/bs";
import { IoIosArrowRoundBack } from "react-icons/io";
import styles from "./Form.module.css";
import { useGetPreSignedUrlMutation } from "../../redux/api/uploadApi";
import {
  useAddClassifiedPostMutation,
  useGetPcategoriesDropDownQuery,
  useGetcategoriesDropDownQuery,
} from "../../redux/api/classifiedApi";
import {
  useGetDistrictsQuery,
  useGetCountriesSelectedQuery,
  useGetAreaDistrictsQuery,
} from "../../redux/api/regionApi";

const AddAutomotiveClassified = ({ classified_id, classifiedType }) => {
  const navigate = useNavigate();
  const filePikerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [imgsrc, setImagesrc] = useState("");
  const Query = new URLSearchParams(useLocation().search);
  const [getPreSignedUrl] = useGetPreSignedUrlMutation();
  const [addClassifiedPost] = useAddClassifiedPostMutation();
  const [imgeFile, setImgeFile] = useState("");
  const [formData, setFormData] = useState({
    country_id: "KW",
    district_id: "",
    area_id: "",
    address: "",
    title: "",
    mobile: "",
    price: "",
    description: "",
    leaseType: "",
    model: "",
    fuelType: "",
    transmission: "",
    cylinder: "",
    isFullOption: false,
    isInsured: false,
  });

  const {
    data: modelData,
    error: modelError,
    isSuccess: modelSuccess,
  } = useGetcategoriesDropDownQuery({
    idName: "model",
    category_id: classified_id,
  });

  const {
    data: fuelTypeData,
    error: fuelTypeError,
    isSuccess: fuelTypeSuccess,
  } = useGetcategoriesDropDownQuery({
    idName: "fuel_type",
    category_id: classified_id,
  });

  const {
    data: transmissionData,
    error: transmissionDataError,
    isSuccess: transmissionDataSuccess,
  } = useGetcategoriesDropDownQuery({
    idName: "transmission",
    category_id: classified_id,
  });

  const {
    data: cylinderData,
    error: cylinderDataError,
    isSuccess: cylinderDataSuccess,
  } = useGetcategoriesDropDownQuery({
    idName: "cylinder",
    category_id: classified_id,
  });

  const {
    data: catPData,
    error: catPError,
    isSuccess: careerPSuccess,
  } = useGetPcategoriesDropDownQuery({
    category_id: classified_id,
  });

  const [errors, setErrors] = useState({});

  const { data, error, isSuccess } = useGetCountriesSelectedQuery({});
  const {
    data: dataDiscrict,
    error: errorDistrict,
    isSuccess: successDistrict,
  } = useGetDistrictsQuery({
    country_id: formData.country_id || "KW",
    searchText: "",
  });

  const {
    data: dataArea,
    error: errorArea,
    isSuccess: successArea,
  } = useGetAreaDistrictsQuery(
    {
      district_id: formData.district_id,
      searchText: "",
    },
    {
      skip: !formData.district_id, // Skip query if district_id is not available
    }
  );

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked, // Toggle between true and false
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  classifiedType = classifiedType.replace("-", "&");

  const validateForm = () => {
    let newErrors = {};
    if (!formData.country_id) newErrors.country = "Country is required";
    if (!formData.district_id) newErrors.district = "District is required";
    if (!formData.title) newErrors.title = "Title is required";
    if (!formData.mobile) {
      newErrors.mobile = "Mobile is required";
    } else if (!/^\d*$/.test(formData.mobile)) {
      newErrors.mobile = "Mobile must be a positive number";
    } else if (Number(formData.mobile) < 0) {
      newErrors.mobile = "Mobile cannot be negative";
    }
    if (!formData.price) {
      newErrors.price = "Price is required";
    } else if (!/^\d*$/.test(formData.price)) {
      newErrors.price = "Price must be a positive number";
    } else if (Number(formData.price) < 0) {
      newErrors.price = "Price cannot be negative";
    }

    // New validations
    if (!formData.leaseType) newErrors.leaseType = "Lease Type is required";
    if (!formData.model) newErrors.model = "Car Model is required";
    if (!formData.fuelType) newErrors.fuelType = "Fuel Type is required";
    if (!formData.transmission)
      newErrors.transmission = "Transmission Type is required";
    if (!formData.cylinder) newErrors.cylinder = "Cylinder Count is required";

    return newErrors;
    // if (!formData.area_id) newErrors.area = "Area is required";
    return newErrors;
  };

  const handleSubmit = (e) => {
    if (loading) {
      alert("Wait, It already adding a post");
      return;
    }
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      // Handle form submission (e.g., send the data to an API)
      console.log("Form submitted successfully", formData);
      if (imgeFile) {
        setLoading(true);
        getPresignedUrl([imgeFile.name], imgeFile.type, imgeFile);
      } else {
        alert("Image is required");
        return;
      }
    }
  };

  const AddClassifiedPostHandler = async (fileurl) => {
    if (typeof Storage !== "undefined") {
      const user = JSON.parse(localStorage.getItem("RaselListing"));
      if (user?._id) {
        const input = {
          data: {
            ...formData,
            user_id: user._id,
            category_id: Query.get("id"),
            media: [{ url: fileurl, type: "image" }],
          },
          apiUrl: "add-automobile-classified",
        };

        let classifiedType = Query.get("classified").replace("-", "&");

        if (!classifiedType) {
          alert("classifiedType is required");
          setLoading(false);
          return;
        }

        const res = await addClassifiedPost(input);

        if (res.error) {
          if (res.error.status === 401) {
            setLoading(false);
            alert("Not authorized, login again");
            window.location.href = "/rm-admin";
            return;
          }

          alert(res.error.data.error);
          setLoading(false);
          return;
        }

        if (res.data.classifiedAdded) {
          setFormData({
            country_id: "KW",
            district_id: "",
            area_id: "",
            address: "",
            title: "",
            mobile: "",
            price: "",
            description: "",
            leaseType: "",
            model: "",
            fuelType: "",
            transmission: "",
            cylinder: "",
            isFullOption: false,
            isInsured: false,
          });
          setImagesrc("");
          setImgeFile("");
          navigate(-1);
        }

        setLoading(false);
      } else {
        alert("User not logged in");
      }
    } else {
      alert("Update browser");
    }
  };

  const getPresignedUrl = async (fileNames, contentType, imgeFile) => {
    const res = await getPreSignedUrl({
      fileNames: fileNames,
      contentType: contentType,
      flocation: "posts",
    });

    if (res.error) {
      setLoading(false);

      if (res.error.status === 401) {
        alert("Not authorized, login again");
        window.location.href = "/rm-admin";
        return;
      }
      alert(res.error.data.error);
      setLoading(false);
    }
    if (res.data) {
      await uploadFile(
        res.data.preSignedUrls[0].preSignedUrlToUplaodToAWS,
        res.data.preSignedUrls[0].pathNametoStoreInDBaftersucessfullupload,
        imgeFile
      );
    }
  };

  const uploadFile = async (prsignedURL, storeTODB, imgeFile) => {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(percent);
      },
    };
    axios
      .put(prsignedURL, imgeFile, options)
      .then((res) => {
        AddClassifiedPostHandler(storeTODB);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const filePickerHandeler = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImgeFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImagesrc(reader.result);
      });
    } else {
      alert("No file selected");
    }
  };

  return (
    <div className="root-top-margin" style={{ background: "white" }}>
      {error && error.data.error && (
        <span className={styles.error}>{error.data.error}</span>
      )}

      {errorDistrict && errorDistrict?.data.error && (
        <span className={styles.error}>{errorDistrict?.data.error}</span>
      )}

      {errorArea && errorArea?.data.error && (
        <span className={styles.error}>{errorArea?.data.error}</span>
      )}

      {catPError && catPError?.data.error && (
        <span className={styles.error}>{catPError?.data.error}</span>
      )}

      {modelError && modelError?.data.error && (
        <span className={styles.error}>{modelError?.data.error}</span>
      )}

      {fuelTypeError && fuelTypeError?.data.error && (
        <span className={styles.error}>{fuelTypeError?.data.error}</span>
      )}

      {transmissionDataError && transmissionDataError?.data.error && (
        <span className={styles.error}>
          {transmissionDataError?.data.error}
        </span>
      )}
      {cylinderDataError && cylinderDataError?.data.error && (
        <span className={styles.error}>{cylinderDataError?.data.error}</span>
      )}

      <IoIosArrowRoundBack
        size={40}
        style={{ cursor: "pointer" }}
        onClick={() => navigate(-1)}
      />
      <div
        style={{
          background: "white",
          width: "80%",
          margin: "auto",
          padding: "10px",
        }}
      >
        <div className={styles.imageWrapper}>
          <input
            type="file"
            ref={filePikerRef}
            accept="image/*"
            className="display-n"
            onChange={filePickerHandeler}
          />
          {imgsrc && (
            <div className="text-align-center">
              <img src={imgsrc} alt="category" className={styles.catImage} />
              <div
                className={styles.uploadImgButton}
                onClick={() => {
                  if (loading) {
                    alert("Please wait, it's uploading");
                  } else {
                    filePikerRef.current.click();
                  }
                }}
              >
                Change Image <BsCloudUpload />
              </div>
            </div>
          )}
          {!imgsrc && (
            <div
              className={styles.uploadImgButton}
              onClick={() => {
                if (loading) {
                  alert("Please wait, it's uploading");
                } else {
                  filePikerRef.current.click();
                }
              }}
            >
              Add Image <BsCloudUpload />
            </div>
          )}
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.field}>
            <label htmlFor="country">Country</label>
            <select
              id="country"
              name="country_id"
              value={formData.country_id}
              onChange={handleChange}
            >
              {isSuccess &&
                data.map((country) => (
                  <option value={country._id} key={country._id}>
                    {country.country_english}
                  </option>
                ))}
            </select>
            {errors.country && (
              <span className={styles.error}>{errors.country}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="district">District</label>
            <select
              id="district"
              name="district_id"
              value={formData.district_id}
              onChange={handleChange}
            >
              <option value="">Select District</option>
              {successDistrict &&
                dataDiscrict.map((district) => (
                  <option value={district._id} key={district._id}>
                    {district.district_en}
                  </option>
                ))}
            </select>
            {errors.district && (
              <span className={styles.error}>{errors.district}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="area">Area</label>
            <select
              id="area"
              name="area_id"
              value={formData.area_id}
              onChange={handleChange}
              disabled={!successArea}
            >
              <option value="">Select Area</option>
              {successArea &&
                dataArea.map((area) => (
                  <option value={area._id} key={area._id}>
                    {area.area_en}
                  </option>
                ))}
            </select>
            {errors.area && <span className={styles.error}>{errors.area}</span>}
          </div>

          <div className={styles.field}>
            <label htmlFor="address">Other Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>

          <div className={styles.field}>
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
            />
            {errors.title && (
              <span className={styles.error}>{errors.title}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="mobile">Mobile</label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
            {errors.mobile && (
              <span className={styles.error}>{errors.mobile}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="price">Price</label>
            <input
              type="text"
              id="price"
              name="price"
              value={formData.price}
              onChange={handleChange}
            />
            {errors.price && (
              <span className={styles.error}>{errors.price}</span>
            )}
          </div>

          {/* New dropdown fields */}
          <div className={styles.field}>
            <label htmlFor="leaseType">Lease Type</label>
            <select
              id="leaseType"
              name="leaseType"
              value={formData.leaseType}
              onChange={handleChange}
            >
              <option value="">Select Lease</option>
              {careerPSuccess &&
                catPData.map((type) => (
                  <option value={type._id} key={type._id}>
                    {type.sub_category_en}
                  </option>
                ))}
            </select>
            {errors.leaseType && (
              <span className={styles.error}>{errors.leaseType}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="model">Car Model</label>
            <select
              id="model"
              name="model"
              value={formData.model}
              onChange={handleChange}
            >
              <option value="">Select Car Model</option>
              {modelSuccess &&
                modelData.map((model) => (
                  <option value={model._id} key={model._id}>
                    {model.value_en}
                  </option>
                ))}
            </select>
            {errors.model && (
              <span className={styles.error}>{errors.model}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="fuelType">Fuel Type</label>
            <select
              id="fuelType"
              name="fuelType"
              value={formData.fuelType}
              onChange={handleChange}
            >
              <option value="">Select Fuel Type</option>
              {fuelTypeSuccess &&
                fuelTypeData.map((fuel) => (
                  <option value={fuel._id} key={fuel._id}>
                    {fuel.value_en}
                  </option>
                ))}
            </select>
            {errors.fuelType && (
              <span className={styles.error}>{errors.fuelType}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="transmission">Transmission Type</label>
            <select
              id="transmission"
              name="transmission"
              value={formData.transmission}
              onChange={handleChange}
            >
              <option value="">Select Transmission Type</option>
              {transmissionDataSuccess &&
                transmissionData.map((transmission) => (
                  <option value={transmission._id} key={transmission._id}>
                    {transmission.value_en}
                  </option>
                ))}
            </select>
            {errors.transmission && (
              <span className={styles.error}>{errors.transmission}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="cylinder">Cylinder Count</label>
            <select
              id="cylinder"
              name="cylinder"
              value={formData.cylinder}
              onChange={handleChange}
            >
              <option value="">Select Cylinder Count</option>
              {cylinderDataSuccess &&
                cylinderData.map((cylinder) => (
                  <option value={cylinder._id} key={cylinder._id}>
                    {cylinder.value_en}
                  </option>
                ))}
            </select>
            {errors.cylinder && (
              <span className={styles.error}>{errors.cylinder}</span>
            )}
          </div>
          <div>
            {/* New checkboxes */}
            <div>
              <label>
                <input
                  type="checkbox"
                  name="isFullOption"
                  checked={formData.isFullOption}
                  onChange={handleChange}
                />
                <span className="ml-1">Full Option</span>
              </label>
            </div>

            <div>
              <label>
                <input
                  type="checkbox"
                  name="isInsured"
                  checked={formData.isInsured}
                  onChange={handleChange}
                />
                <span className="ml-1">Insured</span>
              </label>
            </div>
          </div>
          <div className={styles.fieldFull}>
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="display-flex" style={{ gap: "4px" }}>
            <div className={styles.fieldFull}>
              <button type="submit" className={styles.submitButton}>
                Submit
              </button>
            </div>
            {loading && (
              <div style={{ display: "flex", alignItems: "end" }}>
                <span className="loader-spiner"></span>
                <span className="text-primary">Adding....</span>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAutomotiveClassified;
