import React, { useState, useEffect, useRef } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { BsCloudUpload } from "react-icons/bs";
import AddClassiedPostCommonFields from "./AddClassiedPostCommonFields";
import styles from "./Form.module.css";
import { useGetPreSignedUrlMutation } from "../../redux/api/uploadApi";
import { useAddClassifiedPostMutation } from "../../redux/api/classifiedApi";
const AddClassifiedPost = () => {
  const navigate = useNavigate();
  const filePikerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [imgsrc, setImagesrc] = useState("");
  const Query = new URLSearchParams(useLocation().search);
  const [getPreSignedUrl] = useGetPreSignedUrlMutation();
  const [addClassifiedPost] = useAddClassifiedPostMutation();
  const [imgeFile, setImgeFile] = useState("");
  const [formData, setFormData] = useState({
    country_id: "KW",
    district_id: "",
    area_id: "",
    address: "",
    title: "",
    mobile: "",
    price: "",
    description: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!Query.get("id")) {
      alert("Classified ID is missing in the URL");
    }
    if (!Query.get("classified")) {
      alert("classified param is missing in the url");
    }
  }, [Query]);

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked, // Toggle between true and false
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  let classifiedType = Query.get("classified").replace("-", "&");

  const validateForm = () => {
    let newErrors = {};
    if (!formData.country_id) newErrors.country = "Country is required";
    if (!formData.district_id) newErrors.district = "District is required";
    if (!formData.title) newErrors.title = "Title is required";
    if (!formData.mobile) {
      newErrors.mobile = "Mobile is required";
    } else if (!/^\d*$/.test(formData.mobile)) {
      newErrors.mobile = "Mobile must be a posive number";
    } else if (Number(formData.mobile) < 0) {
      newErrors.mobile = "Mobile cannot be negative";
    }
    if (!formData.price) {
      newErrors.price = "Price is required";
    } else if (!/^\d*$/.test(formData.price)) {
      newErrors.price = "Price must be a posive number";
    } else if (Number(formData.price) < 0) {
      newErrors.price = "Price cannot be negative";
    }

    if (classifiedType === "buy & sell") {
      if (
        typeof formData.isItOldNotNew === "undefined" ||
        !formData.isItOldNotNew
      ) {
        newErrors.isItOldNotNew = "old or new is required";
      }
    }

    if (classifiedType === "jobs") {
      if (
        typeof formData.careerLevel === "undefined" ||
        !formData.careerLevel
      ) {
        newErrors.careerLevel = "career Type is required";
      }
    }

    if (classifiedType === "property") {
      if (typeof formData.leaseType === "undefined" || !formData.leaseType) {
        newErrors.leaseType = "lease Type is required";
      }
    }

    if (classifiedType === "property") {
      console.log(formData.buildingType, "formData.buildingType");
      if (
        typeof formData.buildingType === "undefined" ||
        !formData.buildingType
      ) {
        newErrors.buildingType = "building Type is required";
      }

      if (typeof formData.street === "undefined" || !formData.street) {
        newErrors.street = "Street is required";
      }

      if (typeof formData.block === "undefined" || !formData.block) {
        newErrors.block = "Block is required";
      }
      if (!/^\d*$/.test(formData.block)) {
        newErrors.block = "block must be a posive number";
      }

      if (typeof formData.buildingNo === "undefined" || !formData.buildingNo) {
        newErrors.buildingNo = "Building number is required";
      }
      if (!/^\d*$/.test(formData.buildingNo)) {
        newErrors.buildingNo = "Building number must be a posive number";
      }

      if (typeof formData.floor === "undefined" || !formData.floor) {
        newErrors.floor = "Floor is required";
      }
      if (!/^\d*$/.test(formData.floor)) {
        newErrors.floor = "floor must be a posive number";
      }

      if (typeof formData.flatNo === "undefined" || !formData.flatNo) {
        newErrors.flatNo = "Flat number is required";
      }
      if (!/^\d*$/.test(formData.flatNo)) {
        newErrors.flatNo = "flatNo must be a posive number";
      }

      if (typeof formData.blockNo === "undefined" || !formData.blockNo) {
        newErrors.blockNo = "Block number is required";
      }
      if (!/^\d*$/.test(formData.blockNo)) {
        newErrors.blockNo = "blockNo must be a posive number";
      }

      if (typeof formData.rooms === "undefined" || !formData.rooms) {
        newErrors.rooms = "Number of rooms is required";
      }

      if (!/^\d*$/.test(formData.rooms)) {
        newErrors.rooms = "rooms must be a posive number";
      }

      if (
        typeof formData.livingRooms === "undefined" ||
        !formData.livingRooms
      ) {
        newErrors.livingRooms = "Number of living rooms is required";
      }
      if (!/^\d*$/.test(formData.livingRooms)) {
        newErrors.livingRooms = "livingRooms must be a posive number";
      }

      if (!/^\d*$/.test(formData.bathrooms)) {
        newErrors.bathrooms = "bathrooms  must be a posive number";
      }
    }

    if (typeof formData.carParking === "undefined") {
      formData.carParking = false;
    }

    if (typeof formData.bikeParking === "undefined") {
      formData.bikeParking = false;
    }
    // if (!formData.area_id) newErrors.area = "Area is required";
    return newErrors;
  };

  const handleSubmit = (e) => {
    if (loading) {
      alert("Wait, It already adding a post");
      return;
    }
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      // Handle form submission (e.g., send the data to an API)
      console.log("Form submitted successfully", formData);
      if (imgeFile) {
        setLoading(true);
        getPresignedUrl([imgeFile.name], imgeFile.type, imgeFile);
      } else {
        alert("image is required");
        return;
      }
    }
  };

  const AddClassifiedPostHandler = async (fileurl) => {
    if (typeof Storage !== "undefined") {
      const user = JSON.parse(localStorage.getItem("RaselListing"));
      if (user?._id) {
        const input = {
          data: {
            ...formData,
            user_id: user._id,
            category_id: Query.get("id"),
            media: [{ url: fileurl, type: "image" }],
            isItOldNotNew: formData.isItOldNotNew === "old" ? true : false,
          },
        };

        console.log(input, "input");
        let classifiedType = Query.get("classified").replace("-", "&");

        if (!classifiedType) {
          alert("classifiedType is required");
          setLoading(false);
          return;
        }
        if (classifiedType === "services") {
          input.apiUrl = "add-service-classified";
        } else if (classifiedType === "animals & pets") {
          input.apiUrl = "add-animals-pets-classified";
        } else if (classifiedType === "men clothing") {
          input.apiUrl = "add-men-clothing-classified";
        } else if (classifiedType === "women clothing") {
          input.apiUrl = "add-women-clothing-classified";
        } else if (classifiedType === "fashion & beauty") {
          input.apiUrl = "add-fashion-beauty-classified";
        } else if (classifiedType === "food") {
          input.apiUrl = "add-food-classified";
        } else if (classifiedType === "buy & sell") {
          input.apiUrl = "add-buy-sale-classified";
        } else if (classifiedType === "jobs") {
          input.apiUrl = "add-jobs-classified";
        } else if (classifiedType === "property") {
          input.apiUrl = "add-property-classified";
        } else if (classifiedType === "boat") {
          input.apiUrl = "add-boat-classified";
        } else if (classifiedType === "bike") {
          input.apiUrl = "add-bike-classified";
        } else {
          alert("api is missing");
          setLoading(false);
          return;
        }

        console.log(classifiedType, "classifiedType");

        console.log(input, "input");

        const res = await addClassifiedPost(input);

        if (res.error) {
          if (res.error.status === 401) {
            setLoading(false);
            alert("not authorized login again");
            Window.location.href("/rm-admin");
            return;
          }

          alert(res.error.data.error);
          setLoading(false);
          return;
        }

        console.log(res.data);
        if (res.data.classifiedAdded) {
          setFormData({
            country_id: "KW",
            district_id: "",
            area_id: "",
            address: "",
            title: "",
            mobile: "",
            price: "",
            description: "",
          });
          setImagesrc("");
          setImgeFile("");
          navigate(-1);
        }

        setLoading(false);
      } else {
        alert("user not logged in");
      }
    } else {
      alert("update browser");
    }
    console.log(fileurl);
  };

  const getPresignedUrl = async (fileNames, contentType, imgeFile) => {
    const res = await getPreSignedUrl({
      fileNames: fileNames,
      contentType: contentType,
      flocation: "posts",
    });

    if (res.error) {
      setLoading(false);

      if (res.error.status === 401) {
        alert("not authorized login again");
        Window.location.href("/rm-admin");
        return;
      }
      alert(res.error.data.error);
      setLoading(false);
    }
    if (res.data) {
      await uploadFile(
        res.data.preSignedUrls[0].preSignedUrlToUplaodToAWS,
        res.data.preSignedUrls[0].pathNametoStoreInDBaftersucessfullupload,
        imgeFile
      );
    }
  };

  const uploadFile = async (prsignedURL, storeTODB, imgeFile) => {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        console.log(percent);
      },
    };
    axios
      .put(prsignedURL, imgeFile, options)
      .then((res) => {
        AddClassifiedPostHandler(storeTODB);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const filePickerHandeler = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImgeFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImagesrc(reader.result);
      });
    } else {
      alert("no file selected");
    }
  };

  return (
    <div className="root-top-margin" style={{ background: "white" }}>
      <IoIosArrowRoundBack
        size={40}
        style={{ cursor: "pointer" }}
        onClick={() => navigate(-1)}
      />
      <div
        style={{
          background: "white",
          width: "80%",
          margin: "auto",
          padding: "10px",
        }}
      >
        <div className={styles.imageWrapper}>
          <input
            type="file"
            ref={filePikerRef}
            accept="image/*"
            className="display-n"
            onChange={filePickerHandeler}
          />
          {imgsrc && (
            <div className="text-align-center">
              <img src={imgsrc} alt="catgeory" className={styles.catImage} />
              <div
                className={styles.uploadImgButton}
                onClick={() => {
                  if (loading) {
                    alert("please wait its uploading");
                  } else {
                    filePikerRef.current.click();
                  }
                }}
              >
                Change Image <BsCloudUpload />
              </div>
            </div>
          )}
          {!imgsrc && (
            <div
              className={styles.uploadImgButton}
              onClick={() => {
                if (loading) {
                  alert("please wait its uploading");
                } else {
                  filePikerRef.current.click();
                }
              }}
            >
              Add Image <BsCloudUpload />
            </div>
          )}
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <AddClassiedPostCommonFields
            formData={formData}
            handleChange={handleChange}
            errors={errors}
            classifiedType={classifiedType}
            classified_id={Query.get("id")}
          />
          <div className="display-flex" style={{ gap: "4px" }}>
            <div className={styles.fieldFull}>
              <button type="submit" className={styles.submitButton}>
                Submit
              </button>
            </div>
            {loading && (
              <div style={{ display: "flex", alignItems: "end" }}>
                <span className="loader-spiner"></span>
                <span className="text-primary">adding....</span>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddClassifiedPost;
